<template>
  <div class="singleRGBA">
    <div class="banner">
      <div class="bannerBox">
        <div class="name">RGBW灯带</div>
      </div>
    </div>
    <!-- 相关参数 -->
    <div class="paramBox">
      <div class="param">
        <div class="title">RGBW灯带</div>
        <div class="paramTit">相关参数</div>
        <div class="pas">
          <div class="pasLeft">
            <div class="li">
              <div class="pasName">产品尺寸:</div>
              <div class="pasCont">定制</div>
            </div>

            <div class="li">
              <div class="pasName">额定电压:</div>
              <div class="pasCont">3V</div>
            </div>
            <div class="li">
              <div class="pasName">额定输入:</div>
              <div class="pasCont">DC12V/24V</div>
            </div>
          </div>
          <div class="pasRight">
            <div class="li">
              <div class="pasName">额定功率:</div>
              <div class="pasCont">5W/米</div>
            </div>
            <div class="li">
              <div class="pasName">工作温度:</div>
              <div class="pasCont">0°C-40°C</div>
            </div>
            <div class="li">
              <div class="pasName">工作湿度:</div>
              <div class="pasCont">0%-95%RH，无冷凝</div>
            </div>
          </div>
        </div>
        <div class="text">
          <div class="tx">RGBW灯带，需要配合集中式LED调色控制器，RGBW灯带可以实现红、绿、蓝、白四种颜色，可以依次变幻、跑马和闪烁等效果。</div>
        </div>
        <div class="star">* 以上所有数据来自BOT实验室，依据测量方式不同,实际结果可能略有差异。</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
// banner
.banner {
  width: 100%;
  height: 6rem;
  background: url("../../../assets/product/singleRGBA.png");
  background-size: 100% 100%;
  .bannerBox {
    width: 12rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .name {
      display: block;
      text-align: left;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #333333;
    }
  }
}
// 相关参数
.paramBox {
  width: 100%;
  background-color: #fff;
  .param {
    width: 12rem;
    margin: 0 auto;
    padding-bottom: 1.2rem;
    text-align: left;
    .title {
      padding: 0.39rem 0px;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      color: #ff9000;
      border-bottom: 1px solid #333;
    }
    .paramTit {
      margin-top: 0.38rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333;
    }
    .pas {
      margin-top: 0.79rem;
      display: flex;
      text-align: left;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
        .li {
          display: flex;
          flex-direction: row;
          margin-bottom: 0.38rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
          &:nth-of-type(4) {
            .pasName {
              margin-right: 1.45rem;
            }
          }

          &:last-of-type {
            margin-bottom: 0px;
          }
          .pasName {
            margin-right: 1.19rem;
          }
        }
      }
      .pasRight {
        .li {
          .pasName {
            margin-right: 1.94rem;
          }
        }
      }
    }
    .text {
      margin-top: 0.43rem;
      border-top: 1px solid #333;
      padding-top: 0.4rem;
      text-align: justify;
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .star {
      margin-top: 0.8rem;
      text-align: left;
       font-size: 0.1rem;
      line-height: 0.1rem;
      font-weight: 300;
      color: #333333;
    }
  }
}
</style>
